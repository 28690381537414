import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';
import { ContentRows } from 'modules/content-rows';

const SPrefixLangLink = styled(PrefixLangLink)`
    color: inherit;
    text-decoration: underline;
`;

export const PropTechSection = () => {
    const { formatMessage } = useIntl();

    const images = useStaticQuery(graphql`
        {
            realEstate: file(
                relativePath: { eq: "prop-tech-real-estate/real-estate.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        formats: [WEBP]
                    )
                }
            }
            mobile: file(
                relativePath: {
                    eq: "prop-tech-real-estate/mobile-on-table.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return (
        <ContentRows
            columnsData={[
                {
                    descriptions: [
                        formatMessage(
                            {
                                id: 'view-prop-tech-real-estate.propTechIsOne',
                            },
                            {
                                link: (chunks) => {
                                    return (
                                        <SPrefixLangLink
                                            to={`${PATHS.SERVICES}/${PATHS.DATA_SCIENCE}`}
                                        >
                                            {chunks[0]}
                                        </SPrefixLangLink>
                                    );
                                },
                            },
                        ),
                    ],
                    img: images.realEstate,
                    alt: formatMessage({
                        id: 'view-prop-tech-real-estate.propTechIsOneAlt',
                    }),
                },
                {
                    descriptions: [
                        formatMessage({
                            id: 'view-prop-tech-real-estate.modernConsumer',
                        }),
                    ],
                    img: images.mobile,
                    alt: formatMessage({
                        id: 'view-prop-tech-real-estate.modernConsumerAlt',
                    }),
                    isReversed: true,
                },
            ]}
        />
    );
};
