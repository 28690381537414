import React from 'react';
import { SeeAllCaseStudiesSection } from 'modules/see-all-case-studies-section';

import { usePropTechData } from './constant';

export const PropTechStudiesSection = () => {
    return (
        <SeeAllCaseStudiesSection
            title="caseStudies"
            description="exploreOurPastSuccess"
            data={usePropTechData()}
        />
    );
};
