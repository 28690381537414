import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import { ContentRows } from 'modules/content-rows';

import { useRealEstateServices } from './helpers';

const STextWrapper = styled.div`
    text-align: center;
    max-width: 43.75rem;
    margin: 0 auto 6.25rem;
`;

const SHeader = styled(HeaderSecond)`
    margin-bottom: 1.5rem;
`;

export const RealEstateDevelopersSection = () => {
    const realEstateServices = useRealEstateServices();

    const title = 'view-prop-tech-real-estate.whatAreThePros';

    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <>
            <STextWrapper>
                <SHeader id={kebabCaseId} handleCopyUrl={handleCopyUrl}>
                    <FormattedMessage id={title} />
                </SHeader>
                <Paragraph>
                    <FormattedMessage id="view-prop-tech-real-estate.realEstateSoftwareDevelopment" />
                </Paragraph>
            </STextWrapper>
            <ContentRows columnsData={realEstateServices} />
        </>
    );
};
