import React from 'react';
import { useIntl } from 'react-intl';
import { EXTERNAL_REDIRECTS } from 'constants/external-redirects';
import { BadgesSection } from 'modules/badges-section';
import { StyledLink } from 'modules/page-footer/components/styled-link';
import ClutchTopWebLogo from 'svgs/logos/clutch-top-web.svg';

export const PropTechBadgesSection = () => {
    const { formatMessage } = useIntl();

    return (
        <BadgesSection>
            <StyledLink
                href={EXTERNAL_REDIRECTS.CLUTCH_TOP_COMPANY_BADGE_URL}
                ariaLabel={formatMessage({
                    id: 'footer.clutch.alts.webDevelopers',
                })}
            >
                <ClutchTopWebLogo width={240} height={259} />
            </StyledLink>
        </BadgesSection>
    );
};
