import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { REAL_ESTATE_SOFTWARE_DATA } from 'views/prop-tech/real-estate-software-section/constants';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { PATHS } from 'constants/paths';
import { SectionTilesSection } from 'modules/section-tiles-section';

const SPrefixLangLink = styled(PrefixLangLink)`
    color: inherit;
    text-decoration: underline;
`;

export const RealEstateSoftwareSection = () => {
    const { formatMessage } = useIntl();

    return (
        <SectionTilesSection
            title="view-prop-tech-real-estate.aRealEstateSoftwareDevelopment"
            paragraph={formatMessage(
                {
                    id: 'view-prop-tech-real-estate.weCreateReliable',
                },
                {
                    link: (chunks) => (
                        <SPrefixLangLink
                            to={PATHS.SOFTWARE_DEVELOPMENT_PROCESS}
                        >
                            {chunks}
                        </SPrefixLangLink>
                    ),
                },
            )}
            data={REAL_ESTATE_SOFTWARE_DATA}
            isParagraphFormattedPreviously
        />
    );
};
