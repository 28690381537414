import { useIntl } from 'react-intl';
import { graphql, useStaticQuery } from 'gatsby';

export const useRealEstateServices = () => {
    const { formatMessage } = useIntl();
    const images = useStaticQuery(graphql`
        {
            map: file(
                relativePath: {
                    eq: "prop-tech-real-estate/real-estate-map.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        formats: [WEBP]
                    )
                }
            }
            app: file(
                relativePath: {
                    eq: "prop-tech-real-estate/real-estate-app.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return [
        {
            list: [
                {
                    title: 'view-prop-tech-real-estate.marketMonitoring',
                    paragraph: 'view-prop-tech-real-estate.stayUpToDate',
                },
                {
                    title: 'view-prop-tech-real-estate.immersiveExperience',
                    paragraph: 'view-prop-tech-real-estate.offerYourCustomers',
                },
                {
                    title: 'view-prop-tech-real-estate.onlineManagement',
                    paragraph:
                        'view-prop-tech-real-estate.saveTimeWithConvenience',
                },
                {
                    title: 'view-prop-tech-real-estate.security',
                    paragraph: 'view-prop-tech-real-estate.getInformation',
                },
            ],
            img: images.map,
            alt: formatMessage({
                id: 'view-prop-tech-real-estate.whatAreTheProsFirstAlt',
            }),
            isReversed: true,
        },
        {
            list: [
                {
                    title: 'view-prop-tech-real-estate.usability',
                    paragraph: 'view-prop-tech-real-estate.userCentered',
                },
                {
                    title: 'view-prop-tech-real-estate.automation',
                    paragraph: 'view-prop-tech-real-estate.createAutomated',
                },
                {
                    title: 'view-prop-tech-real-estate.realTimeUpdates',
                    paragraph: 'view-prop-tech-real-estate.keepYourTenants',
                },
                {
                    title: 'view-prop-tech-real-estate.support',
                    paragraph: 'view-prop-tech-real-estate.haveAnAccess',
                },
            ],
            img: images.app,
            alt: formatMessage({
                id: 'view-prop-tech-real-estate.whatAreTheProsSecondAlt',
            }),
        },
    ];
};
