import React from 'react';
import Cube from 'svgs/icons/ic-cube.svg';
import Flag from 'svgs/icons/ic-flag-2.svg';
import PresentationChart from 'svgs/icons/ic-presentation-chart.svg';

export const REAL_ESTATE_SOFTWARE_DATA = [
    {
        icon: <Flag width={42} height={42} />,
        text: 'view-prop-tech-real-estate.customSoftware',
        description: 'view-prop-tech-real-estate.aSoftwareTailored',
    },
    {
        icon: <PresentationChart width={42} height={42} />,
        text: 'view-prop-tech-real-estate.crmSoftware',
        description: 'view-prop-tech-real-estate.aHandyTechnology',
    },
    {
        icon: <Cube width={42} height={42} />,
        text: 'view-prop-tech-real-estate.erpSoftware',
        description: 'view-prop-tech-real-estate.softwareSolution',
    },
];
