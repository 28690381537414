import { CASE_STUDIES_TAG } from 'views/case-studies/constants';
import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';

export const usePropTechData = () => {
    const images = useStaticQuery(graphql`
        {
            desa: file(
                relativePath: { eq: "case-studies/desa-locum-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            turo: file(
                relativePath: { eq: "case-studies/turo-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            cbre: file(
                relativePath: { eq: "case-studies/cbre-zone-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return [
        {
            title: 'case-studies-page.desaLocum',
            field: 'case-studies-page.field.realEstate',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.desa.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.REAL_ESTATE_AUCTION_SOFTWARE}`,
        },
        {
            title: 'case-studies-page.turo',
            field: 'case-studies-page.field.realEstate',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.turo.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.CONSTRUCTION_MANAGEMENT_SOFTWARE_TURO}`,
        },
        {
            title: 'case-studies-page.cbreZone',
            field: 'case-studies-page.field.realEstate',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.cbre.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.COMMERCIAL_REAL_ESTATE_SOFTWARE_ZONE}`,
        },
    ];
};
