import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { AnimatedRealEstate } from 'components/animated-real-estate';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { PATHS } from 'constants/paths';
import { MainSection } from 'modules/main-section';

const SPrefixLangLink = styled(PrefixLangLink)`
    color: inherit;
    text-decoration: underline;
`;

export const PropTechMainSection = () => {
    const { formatMessage } = useIntl();

    return (
        <MainSection
            icon={<AnimatedRealEstate />}
            iconWrapperCss={css`
                max-width: 33.125rem;
            `}
            titleId="view-prop-tech-real-estate.realEstateSoftwareSolutions"
            paragraphs={[
                formatMessage(
                    {
                        id: 'view-prop-tech-real-estate.revolutionizeYourRealEstate',
                    },
                    {
                        link: (chunks) => (
                            <SPrefixLangLink to={PATHS.CASE_STUDIES}>
                                {chunks}
                            </SPrefixLangLink>
                        ),
                    },
                ),
            ]}
            estimateProjectAnalytics={
                gaTrackedEvents.REAL_ESTATE.CTA.ESTIMATE_PROJECT
            }
            intoCallAnalytics={gaTrackedEvents.REAL_ESTATE.CTA.INTRO_CALL}
            isParagraphFormattedPreviously
        />
    );
};
