import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { PATHS } from 'constants/paths';
import ProptechSvg from 'svgs/blog/proptech.svg';

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    tags: [BLOG_POST_TAGS.PROPTECH],
    button: {
        to: `${PATHS.BLOG}/${PATHS.PROPTECH}`,
        label: 'view-prop-tech-real-estate.footer.button',
    },
    categoryBanner: {
        title: 'view-prop-tech-real-estate.footer.title',
        description: 'view-prop-tech-real-estate.footer.description',
        svg: ProptechSvg,
    },
};
